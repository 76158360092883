<template>
    <div>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          :title="'ข้อมูลบุคลากร' + ivens.user_firstname"
          class="px-5 py-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
            single-line
            hide-details
            v-on:keyup.enter="OnEnter()"
            filled
            class="mb-2"
          />
          <div class="red--text">
            *กรุณาปรับปรุงวันที่ได้รับวิทยฐานะ ใช้ในกรณีสอบตำแหน่ง รองผู้อำนวยวิทยาลัย
          </div>
          <v-data-table
            :headers="headers"
            :items="personnel_temporarys"
            class="elevation-1"
            :loading="loading"
            :search="search"
          >
        
            <template v-slot:item.brith_day="{ item }">
              {{
                item.brith_day + "/" + item.brith_month + "/" + item.brith_year
              }}
            </template>
            <template v-slot:item.appoin_day="{ item }">
              {{
                item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
              }}
            </template>


            <template v-slot:item.date_rang_level="{item}">
              <div v-if="String(item.date_rang_level)>4">
                {{ item.date_rang_level | moment("add","543 year") | moment("D MMMM YYYY") }}
              </div>
              <div v-else>
                <v-chip color="warning"> <v-icon>mdi-alert</v-icon> ไม่ปรากฎวันที่ได้รับวิทยฐานะ </v-chip>
              </div>
            </template>
  
            <template v-slot:item.date_app_now="{ item }">
              {{
                item.date_app_now
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.date_app_positonnow="{ item }">
              {{
                item.date_app_positonnow
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
  
            <template v-slot:item.pt_licencedr="{ item }">
              <v-btn
                v-if="item.pt_licencedr_file"
                fab
                outlined
                small
                color="info"
                @click="viewpdflicensedr(item.pt_licencedr_file)"
                ><v-icon>mdi-printer</v-icon></v-btn
              >
              {{ item.pt_licencedr }}
            </template>
  
            <template v-slot:item.pt_ondatedr="{ item }">
              {{
                item.pt_ondatedr
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.pt_enddatedr="{ item }">
              {{
                item.pt_enddatedr
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY")
              }}
            </template>
  
            <template v-slot:item.Agelicencedr="{ item }">
              <div v-if="!item.Agelicencedr">
                <v-chip color="warning" dark>
                  <v-icon>mdi-information-outline</v-icon>
                  <span class="font-weight-bold"> </span>
                </v-chip>
              </div>
  
              <div v-else-if="item.Agelicencedr <= 60">
                <v-chip color="red" dark>
                  <span class="font-weight-bold">
                    {{ item.Agelicencedr || "" }}
                  </span>
                </v-chip>
              </div>
              <div v-else-if="item.Agelicencedr <= 180">
                <v-chip color="warning" dark>
                  <span class="font-weight-bold">
                    {{ item.Agelicencedr || "" }}
                  </span>
                </v-chip>
              </div>
  
              <div v-else>
                <span class="font-weight-bold green--text">
                  {{ item.Agelicencedr || "" }}
                </span>
              </div>
            </template>
  
  
  
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="warning"
                @click.stop="personnel_temporaryEdit(item.id_card)"
              >
                mdi-pencil
              </v-icon>
            </template>
  
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </base-material-card>
  
        <!-- V-model editpersonnel_temporarydialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="editpersonnel_temporarydialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูลผู้ใช้งานระบบ"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editpersonnel_temporaryform" lazy-validation>
                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >1. ข้อมูลพื้นฐาน</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="12">
                      <v-alert type="info" outlined>
                        <div class="text-center">
                          {{ editpersonnel_temporary.id_card }} :
                          {{ editpersonnel_temporary.frist_names }}
                        </div>
                      </v-alert>
                    </v-col>
  
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-cellphone"
                        rounded
                        outlined
                        label="เบอร์โทร"
                        v-model="editpersonnel_temporary.tel_p"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-email"
                        rounded
                        outlined
                        label="E-mail"
                        v-model="editpersonnel_temporary.e_mail"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >2. วุฒิการศึกษา</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="คุณวิฒิ"
                        v-model="editpersonnel_temporary.ed_abb"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="สาขาวิชา"
                        v-model="editpersonnel_temporary.ed_name"
                      ></v-text-field>
                    </v-col>



                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >3. ข้อมูลวิทยฐานะ</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="12">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        type="date"
                        rounded
                        outlined
                        label="วันที่ได้รับวิทยฐานะปัจจุบัน"
                        v-model="editpersonnel_temporary.date_rang_level"
                      ></v-text-field>
                    </v-col>
                  

  
                    <v-col cols="12" md="12">
                      <hr style="border: 1px solid grey;" />
                    </v-col>
                    <v-col cols="12" md="12"
                      ><span class="font-weight-bold"
                        >4. การปฏิบัติงาน ณ ปัจจุบัน</span
                      ></v-col
                    >
  
                    <v-col cols="12" md="2">
                      <v-text-field
                        prepend-icon="mdi-account-settings"
                        rounded
                        outlined
                        label="คำสั่งที่"
                        v-model="editpersonnel_temporary.order_app_now"
                      ></v-text-field>
                    </v-col>
  
                    <v-col cols="12" md="5">
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="editpersonnel_temporary.date_app_now"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ณ สถาบันการอาชีวศึกษาปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu5 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu5.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
  
                    <v-col cols="12" md="5">
                      <v-menu
                        ref="menu6"
                        v-model="menu6"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar-clock"
                            rounded
                            outlined
                            v-model="editpersonnel_temporary.date_app_positonnow"
                            label="วันเดือนปีที่เริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu6 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu6.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>        
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editpersonnel_temporarydialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editpersonnel_temporarySubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
  
  
             <!-- V-model pdflicensedrdialog -->
             <v-layout row justify-center>
          <v-dialog v-model="pdflicensedrdialog" max-width="60%">
            <v-card class="" elevation="2">  
                <embed :src="'/HRvecLicenseDirector/' + pdffiles" width="100%" height="700px">    
            </v-card>
          </v-dialog>
        </v-layout>
  
  
  
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <v-card-text>
            {{ snackbar.text }}
          </v-card-text>
  
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.show = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        loading: false,
        ApiKey: "HRvec2021",
        valid: true,
        addpersonnel_temporarydialog: false,
        editpersonnel_temporarydialog: false,
        deletepersonnel_temporarydialog: false,
        csvpersonnel_temporarydialog: false,
        csvpersonnel_temporaryOutofdialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        personnel_temporarys: [],
        addpersonnel_temporary: {},
        editpersonnel_temporary: {},
        search: "",
        pagination: {},
        headers: [
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "สังกัด",
            align: "left",
            value: "agencys"
          },
          { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
          { text: "คำนำ", align: "center", value: "title_s" },
          { text: "ชื่อ", align: "center", value: "frist_name" },
          { text: "สกุล", align: "center", value: "last_name" },
          { text: "ตำแหน่ง", align: "center", value: "position_name" },
          { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
          { text: "วิทยฐานะ", align: "center", value: "rang_name" },
          { text: "ระดับ", align: "center", value: "rang_level" },
          { text: "วันที่ได้รับวิทยฐานะ", align: "center", value: "date_rang_level" },
          { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
          { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
          { text: "คำสั่งที่", align: "center", value: "order_app_now" },
          {
            text: "วันที่ปฏิบัติหน้าที่ ณ สถาบันการอาชีวศึกษาปัจจุบัน",
            align: "center",
            value: "date_app_now"
          },
          {
            text: "วันที่ดำรงตำแหน่งปัจจุบัน",
            align: "center",
            value: "date_app_positonnow"
          },
          { text: "ใบอนุญาต(ผู้บริหาร)", align: "center", value: "pt_licencedr" },
          {
            text: "วันออกบัตร(ผู้บริหาร)",
            align: "center",
            value: "pt_ondatedr"
          },
          {
            text: "บัตรหมดอายุ(ผู้บริหาร)",
            align: "center",
            value: "pt_enddatedr"
          },
  
          { text: "เกษียณ", align: "center", value: "retrire_year" }
        ],
     
     
  
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
  
        college: {},
  
        prefectures: [],
  
        man_powers: [],
  
        personnel_temporarystatus: [],
  
        region_ena: true,
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,
        data_syslog: {},
        college_search: {},
        position_search: {},
        rangname_search: {},
        upcsv_file: {},
        file2: null,
        type_personnelApp: [
          { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
          { text: "ครูโอนย้าย", value: "transfer_position" },
          { text: "คัดเลือก(ภายใน)", value: "select" },
          { text: "แข่งขัน(ภายนอก)", value: "recruit" }
        ],
  
        rang_names: [
          { text: "-", value: "-" },
          { text: "ต้น", value: "ต้น" },
          { text: "ปฏิบัติงาน", value: "ปฏิบัติงาน" },
          { text: "ปฏิบัติการ", value: "ปฏิบัติการ" },
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "ครู", value: "ครู" },
          { text: "ชำนาญงาน", value: "ชำนาญงาน" },
          { text: "ชำนาญการ", value: "ชำนาญการ" },
          { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
          { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
          { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
        ],
        rang_levels: [
          { text: "-", value: "-" },
          { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" }
        ],
        validateInputcheck: [],
        days: [
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
          { text: "6", value: "6" },
          { text: "7", value: "7" },
          { text: "8", value: "8" },
          { text: "9", value: "9" },
          { text: "10", value: "10" },
          { text: "11", value: "11" },
          { text: "12", value: "12" },
          { text: "13", value: "13" },
          { text: "14", value: "14" },
          { text: "15", value: "15" },
          { text: "16", value: "16" },
          { text: "17", value: "17" },
          { text: "18", value: "18" },
          { text: "19", value: "19" },
          { text: "20", value: "20" },
          { text: "21", value: "21" },
          { text: "22", value: "22" },
          { text: "23", value: "23" },
          { text: "24", value: "24" },
          { text: "25", value: "25" },
          { text: "26", value: "26" },
          { text: "27", value: "27" },
          { text: "28", value: "28" },
          { text: "29", value: "29" },
          { text: "30", value: "30" },
          { text: "31", value: "31" }
        ],
        mounts: [
          { text: "1", value: "1" },
          { text: "2", value: "2" },
          { text: "3", value: "3" },
          { text: "4", value: "4" },
          { text: "5", value: "5" },
          { text: "6", value: "6" },
          { text: "7", value: "7" },
          { text: "8", value: "8" },
          { text: "9", value: "9" },
          { text: "10", value: "10" },
          { text: "11", value: "11" },
          { text: "12", value: "12" }
        ],
        years: [
          { text: "2500", value: "2500" },
          { text: "2501", value: "2501" },
          { text: "2502", value: "2502" },
          { text: "2503", value: "2503" },
          { text: "2504", value: "2504" },
          { text: "2505", value: "2505" },
          { text: "2506", value: "2506" },
          { text: "2507", value: "2507" },
          { text: "2508", value: "2508" },
          { text: "2509", value: "2509" },
          { text: "2510", value: "2510" },
          { text: "2511", value: "2511" },
          { text: "2512", value: "2512" },
          { text: "2513", value: "2513" },
          { text: "2514", value: "2514" },
          { text: "2515", value: "2515" },
          { text: "2516", value: "2516" },
          { text: "2517", value: "2517" },
          { text: "2518", value: "2518" },
          { text: "2519", value: "2519" },
          { text: "2520", value: "2520" },
          { text: "2521", value: "2521" },
          { text: "2522", value: "2522" },
          { text: "2523", value: "2523" },
          { text: "2524", value: "2524" },
          { text: "2525", value: "2525" },
          { text: "2526", value: "2526" },
          { text: "2527", value: "2527" },
          { text: "2528", value: "2528" },
          { text: "2529", value: "2529" },
          { text: "2530", value: "2530" },
          { text: "2531", value: "2531" },
          { text: "2532", value: "2532" },
          { text: "2533", value: "2533" },
          { text: "2534", value: "2534" },
          { text: "2535", value: "2535" },
          { text: "2536", value: "2536" },
          { text: "2537", value: "2537" },
          { text: "2538", value: "2538" },
          { text: "2539", value: "2539" },
          { text: "2540", value: "2540" },
          { text: "2541", value: "2541" },
          { text: "2542", value: "2542" },
          { text: "2543", value: "2543" },
          { text: "2544", value: "2544" },
          { text: "2545", value: "2545" },
          { text: "2546", value: "2546" },
          { text: "2547", value: "2547" },
          { text: "2548", value: "2548" },
          { text: "2549", value: "2549" },
          { text: "2550", value: "2550" },
          { text: "2551", value: "2551" },
          { text: "2552", value: "2552" },
          { text: "2553", value: "2553" },
          { text: "2554", value: "2554" },
          { text: "2555", value: "2555" },
          { text: "2556", value: "2556" },
          { text: "2557", value: "2557" },
          { text: "2558", value: "2558" },
          { text: "2559", value: "2559" },
          { text: "2560", value: "2560" },
  
          { text: "2561", value: "2561" },
          { text: "2562", value: "2562" },
          { text: "2563", value: "2563" },
          { text: "2564", value: "2564" },
          { text: "2565", value: "2565" },
          { text: "2566", value: "2566" },
          { text: "2567", value: "2567" },
          { text: "2568", value: "2568" },
          { text: "2569", value: "2569" },
          { text: "2570", value: "2570" }
        ],
        prefixs: [],
        del_personnel_temporary_del: {},
        del_personnel_temporary_retire: {},
        ins_personnel_temporary_del_retire: {},
  
        updateJ18s: {},
        personnel_temporary_idpositions: [],
  
        user_status_search: "",
        ivens: [],
        pdflicensedrdialog:false,
        pdffiles:[],
      };
    },
  
    async mounted() {
      await this.sweetAlertLoading();
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      
      this.ivens = userSession;
      await this.personnel_temporaryQueryAll();
      let prefix = await this.$http.post("prefix.php", { ApiKey: this.ApiKey });
      this.prefixs = prefix.data;
      Swal.close();
    },
  
    methods: {
      async viewpdflicensedr(pt_licencedr_file){
       this.pdffiles = pt_licencedr_file
        this.pdflicensedrdialog = true
      },
  
      async sweetAlertLoading() {
        Swal.fire({
          title: "Please Wait !",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            
          },
          onBeforeOpen: () => {
            Swal.showLoading();
            
          },
          onAfterClose: () => {
            Swal.hideLoading();
            
          },
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false
        });
      },
  
      async personnel_temporaryQueryAll() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            college_code: this.ivens.user_code
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async searchWait() {
        await this.sweetAlertLoading();
        this.loading = true;
        let result = await this.$http
          .post("personnel_temporary.php", {
            ApiKey: this.ApiKey,
            status_appove: "wait"
          })
          .finally(() => (this.loading = false));
        this.personnel_temporarys = result.data;
        Swal.close();
      },
  
      async personnel_temporaryAdd() {
        this.addpersonnel_temporary = {};
        this.addpersonnel_temporarydialog = true;
      },
  
      async personnel_temporaryCSV() {
        this.csvpersonnel_temporarydialog = true;
      },
  
      async personnel_temporaryOutofCSV() {
        this.csvpersonnel_temporaryOutofdialog = true;
      },
  
      async addpersonnel_temporarySubmit() {
        if (this.$refs.addpersonnel_temporaryform.validate()) {
          this.addpersonnel_temporary.ApiKey = this.ApiKey;
          this.addpersonnel_temporary.p_word =
            this.addpersonnel_temporary.brith_day +
            "/" +
            this.addpersonnel_temporary.brith_month +
            "/" +
            this.addpersonnel_temporary.brith_year;
          let result = await this.$http.post(
            "personnel_temporary.insert.php",
            this.addpersonnel_temporary
          );
          
          if (result.data.status == true) {
            this.personnel_temporary = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "insert";
            this.data_syslog.page_log = "personnel_temporary";
            this.data_syslog.table_log = "personnel_temporary";
            this.data_syslog.detail_log = this.personnel_temporary.id_card;
            this.data_syslog.date_times = this.date_today_log;
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.addpersonnel_temporarydialog = false;
        }
      },
      async personnel_temporaryEdit(id_card) {
        let result = await this.$http.post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: id_card,
          iven: "Ok"
        });
        this.editpersonnel_temporary = result.data;
        this.validateInput();
        this.editpersonnel_temporarydialog = true;
      },
  
      async editpersonnel_temporarySubmit() {
        if (this.$refs.editpersonnel_temporaryform.validate()) {
          this.editpersonnel_temporary.ApiKey = this.ApiKey;
          if (this.editpersonnel_temporary.p_word == "")
            delete this.editpersonnel_temporary.p_word;
  
          if (this.editpersonnel_temporary.rang_condition == null)
            this.editpersonnel_temporary.rang_condition = "0";
  
      
          let result = await this.$http.post(
            "personnel_temporary.update.admin.php",
            this.editpersonnel_temporary
          );
    
          if (result.data.status == true) {
            this.personnel_temporary = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.personnel_temporaryQueryAll();
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "update";
            this.data_syslog.page_log = "personnel_temporary";
            this.data_syslog.table_log = "personnel_temporary";
            this.data_syslog.detail_log = this.personnel_temporary.id_card;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.editpersonnel_temporarydialog = false;
        }
      },
  
      async validateInput() {
        const maxLength = 13;
        const regex = /^[0-9]\d*$/;
        let value = this.editpersonnel_temporary.id_card;
        let i, sum;
        if (
          value !== undefined &&
          value.toString().length == maxLength &&
          value.match(regex)
        ) {
          if (value.length != 13) return false;
          for (i = 0, sum = 0; i < 12; i++) {
            sum += parseInt(value.charAt(i)) * (13 - i);
          }
          let mod = sum % 11;
          let check = (11 - mod) % 10;
          if (check == parseInt(value.charAt(12))) {
            this.validateInputcheck = "true";
          }
        } else {
          this.validateInputcheck = "false";
        }
      }
    },
  
    computed: {
      time_stamp() {
        const d = new Date();
        let time = d.getTime();
        return time;
      },
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      p_word() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let password = dd + "/" + mm + "/" + yyyy;
        return password;
      },
      brith_day() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = parseInt(String(today.getDate()).padStart(2, "0"));
        return dd;
      },
      brith_month() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
        return mm;
      },
      brith_year() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let yyyy = today.getFullYear() + 543;
        return yyyy;
      },
  
      /*  appoin_days(){
            let result 
            let appoin_day=this.editpersonnel_temporary.appoin_day
            let appoin_month=this.editpersonnel_temporary.appoin_month
            let appoin_year=this.editpersonnel_temporary.appoin_year
            result = appoin_year-543 +'-'+ appoin_month +'-'+ appoin_day
            return result
          }, */
  
      appoin_day() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let dd = parseInt(String(today.getDate()).padStart(2, "0"));
        return dd;
      },
      appoin_month() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
        return mm;
      },
      appoin_year() {
        let today = new Date(
          this.addpersonnel_temporary.appoin_days ||
            this.editpersonnel_temporary.appoin_days
        );
        let yyyy = today.getFullYear() + 543;
        return yyyy;
      },
      date_today_cal() {
        let today = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear();
  
        today = yyyy + "-" + mm + "-" + dd;
        return today;
      },
      retrire_year() {
        let mm = this.brith_month;
        let yy_retire = this.brith_year;
        let result;
        if (mm > 9) {
          result = yy_retire + 61;
        } else {
          result = yy_retire + 60;
        }
        return result;
      },
      cal_date_age() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(
          this.addpersonnel_temporary.birthday ||
            this.editpersonnel_temporary.birthday
        );
  
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
  
      cal_date_age_gov() {
        let today = new Date(this.date_today_cal);
        let DOB = new Date(
          this.addpersonnel_temporary.date_app_now ||
            this.editpersonnel_temporary.date_app_now
        );
  
        let totalMonths =
          (today.getFullYear() - DOB.getFullYear()) * 12 +
          today.getMonth() -
          DOB.getMonth();
        totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
        let years = today.getFullYear() - DOB.getFullYear();
        if (DOB.getMonth() > today.getMonth()) years = years - 1;
        else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate()) years = years - 1;
  
        let days;
        let months;
  
        if (DOB.getDate() > today.getDate()) {
          months = totalMonths % 12;
          if (months == 0) months = 11;
          let x = today.getMonth();
          switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
              let a = DOB.getDate() - today.getDate();
              days = 31 - a;
              break;
            }
            default: {
              let a = DOB.getDate() - today.getDate();
              days = 30 - a;
              break;
            }
          }
        } else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
          else months = totalMonths % 12;
        }
        let age = years + " ปี " + months + " เดือน " + days + " วัน";
        return age;
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  